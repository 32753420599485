import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';
import { StyleDTO } from '@/services/MapEditor/domain/model/Style/Style'

export namespace StyleAPI {

    export class AddStyle implements APIRequest<string> {
        response: string;
        path = '/mapeditor/styles';
        method = HTTPMethod.POST;
        parse = (data: AxiosResponse) => data.headers.location
        constructor(public params: StyleDTO) {}
    }

    export class UpdateStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.PUT;
        parse = (data: AxiosResponse) => data.data
        constructor(public params: StyleDTO) {
            this.path = `/mapeditor/styles/${params.guid}`;
        }
    }

    export class DeleteStyle implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.DELETE;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/styles/${guid}`;
        }
    }

    export class GetStyles implements APIRequest<StyleDTO> {
        response: StyleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(payload: string[][]) {
            this.path = '/mapeditor/styles?' +  (new URLSearchParams(payload).toString())
        }
    }

    export class GetStyleByGuid implements APIRequest<StyleDTO> {
        response: StyleDTO;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(guid: string) {
            this.path = `/mapeditor/styles/${guid}`;
        }
    }

    export class GetTypes implements APIRequest<string> {
        response: string;
        path = '/mapeditor/style_types';
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor() {}
    }

    export class GetTypeProperties implements APIRequest<string> {
        response: string;
        path: string;
        method = HTTPMethod.GET;
        parse = (data: AxiosResponse) => data.data
        constructor(val: string) {
            this.path = `/mapeditor/style_types/${val}/properties`;
        }
    }

}